<template>
  <div />
</template>

<script lang="ts">
// eslint-disable-next-line camelcase
// import jwt_decode from 'jwt-decode';

export default {
    created () {
        // const decodedBearer = jwt_decode(this.$auth.$storage.getUniversal('_token.aad'));
        // // console.log('auth created', decodedBearer);
        // this.$auth.$storage.setUniversal('dccsdec', decodedBearer);
        // let ret = {
        //     email: decodedBearer.unique_name
        // };
        // this.$auth.user = ret;
        // console.log('setting user', ret.email);
        // this.$store.dispatch('startup');
    }
};
</script>
